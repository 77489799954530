<template>
  <div class="relative inline-block w-full">
    <label
      v-if="$slots.label"
      :for="id"
      :class="[
        { 'mb-1 block font-semibold': labeltop},
        { 'absolute -mt-3 ml-4': labelup && labelinside},
        { 'absolute text-petrol-500 font-semibold mt-1 ml-11': label && labelinside && $slots.icon},
        { 'absolute text-petrol-500 font-semibold mt-1 ml-4': label && labelinside && !$slots.icon}
      ]"
    >
      <slot name="label" />
    </label>
    <template v-if="$slots.icon">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <slot name="icon" />
      </span>
    </template>
    <select
      :id="id"
      ref="select"
      v-bind="{ title: props.title || undefined }"
      :name="name"
      :value="modelValue"
      :class="[
        { 'border-cta-700 bg-cta-50': props.error && props.errorLevel === 'error' },
        { 'border-neutral-200 bg-white': !props.error && !greypill },
        { 'border-petrol-500 bg-petrol-50': props.error && props.errorLevel === 'info' },
        { 'custom-select bg-neutral-50 rounded-full border border-neutral-50 focus:border-neutral-50 focus:ring-1 focus:ring-transparent font-semibold text-left focus:outline-none custom-select-fontsize cursor-pointer': greypill },
        { 'custom-select border text-left custom-select-fontsize cursor-pointer rounded-xl': normal || slim || xs },
        { 'pl-11 pr-8': $slots.icon },
        { 'pl-4 pr-8': !$slots.icon },
        { 'pt-6 pb-1 font-semibold text-xl rounded-xl h-[55px]': props.label && normal },
        { 'py-0 rounded-md': xs },
        { 'py-2': slim },
        { 'py-3 h-[51.59px] rounded-full focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500': pill },
        { 'py-3 h-[55px] rounded-lg': normal && !props.label },
        { 'select-active': isActive && (normal || slim || xs) },
        { 'select-no-active': !isActive && (normal || slim || xs) },
        props.width
      ]"
      @change="$emit('update:modelValue', $event.target.options[$event.target.selectedIndex]._value);"
      @click="toggleActiveClass"
    >
      <slot />
    </select>
  </div>
</template>
<script setup lang="ts">
import { getRandomId } from '~/utils/Utils'
const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  label: {
    type: String,
    default: null
  },
  title: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    default: 'inside'
  },
  name: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: getRandomId
  },
  modelValue: {
    type: [String, Number, Array],
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  errorLevel: {
    type: String,
    default: 'error'
  },
  width: {
    type: String,
    default: 'w-full'
  }
})

// Label choices
const label = computed(() => props.label === 'normal')
const labelup = computed(() => props.label === 'up')

const labeltop = computed(() => props.labelPosition === 'top')
const labelinside = computed(() => props.labelPosition === 'inside')

const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')
const pill = computed(() => props.design === 'pill')
const xs = computed(() => props.design === 'xs')
const greypill = computed(() => props.design === 'greypill')

const select = ref(null)
const isActive = ref(false)
const isWatcherTriggered = ref(false)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  const elem = select.value
  elem.value = props.modelValue
  elem.addEventListener('blur', handleBlur)
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
  const elem = select.value
  elem.removeEventListener('blur', handleBlur)
})

const toggleActiveClass = () => {
  if (!isWatcherTriggered.value) {
    isActive.value = !isActive.value
  }
}
const handleScroll = () => {
  isActive.value = false
}
const handleBlur = () => {
  isActive.value = false
}
watch(() => props.modelValue, () => {
  isWatcherTriggered.value = true
  isActive.value = false
  setTimeout(() => {
    isWatcherTriggered.value = false
  }, 0)
})

defineEmits<{(e: 'update:modelValue', value: string): void}>()
</script>
<style lang="scss" scoped>
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url('/svg/icons/select-arrow-uiselect.svg');
  background-size: 12px 12px;
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
}
.select-active {
  @apply border-petrol-500 outline-none ring-1 ring-petrol-500;
}
.select-no-active {
  @apply border-neutral-200 focus:border-neutral-200 focus:ring-0 focus:ring-neutral-200 focus:outline-none outline-none;
}
</style>
